<template>
    <v-container>
        <base-material-card icon="mdi-basket" title="Productos" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'ProductoCreate'})">Nuevo</v-btn>
                    <v-text-field v-model="pagination.search" append-icon="mdi-magnify" label="Búsqueda" single-line hide-details @keyup="pagination.currentPage = 1; load()"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="productos" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.precios="{ item }">
                            <span v-if="item.precios.length">
                                {{formatoMoneda(item.precios[0].precio)}}
                            </span>
                        </template>
                        <template v-slot:item.accciones="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ProductoEdit',params: {id: item.id}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ProductoPrecios', params: {id: item.id}})">
                                        <v-icon>far fa-list-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>Precios</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="toggle(item)">
                                        <v-icon v-text="item.habilitado ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></v-icon>
                                    </v-btn>
                                </template>
                                <span v-text="item.habilitado ? 'Deshabilitar' : 'Habilitar'"></span>
                            </v-tooltip>

                            <!-- <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ProductoAddendas', params: {id: item.id}})">
                                        <v-icon>far fa-plus-square</v-icon>
                                    </v-btn>
                                </template>
                                <span>Addendas</span>
                            </v-tooltip> -->

                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.currentPage" class="my-4" :length="pagination.lastPage" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Código', value: 'codigo', sortable: false },
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'Familia', value: 'familia.nombre', sortable: false },
                { text: 'Precio neto', value: 'precios', sortable: false},
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            productos: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                currentPage: 1,
                search: null
            }
        }),
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.$http.get('/productos/', {
                    params: {
                        page: this.pagination.currentPage,
                        search: this.pagination.search
                    }
                }).then(response => {
                    this.productos = response.data.data
                    this.pagination.lastPage = response.data.last_page
                    this.pagination.currentPage = response.data.current_page
                })
            },
            toggle(item) {
                this.$http.put(`/productos/${item.id}/toggle`).then(response => {
                    this.productos.splice(this.productos.indexOf(item), 1, response.data)
                })
            }
        }
    }
</script>

<style>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
</style>